import { request } from '@utils/request';

export const saveAuthor = (category) =>
  request({
    url: '/author/save',
    method: 'POST',
    params: {},
    data: category,
  });

export const getAuthors = ({ page, size }) =>
  request({
    url: '/author',
    method: 'GET',
    params: { page, size },
    data: {},
  });

export const deleteAuthor = (id) =>
  request({
    url: `/author/${id}`,
    method: 'DELETE',
    params: id,
    data: {},
  });
  
export const getHistoriesChange = ({ id, page, size }) =>
  request({
    url: `/author/${id}/histories`,
    method: 'GET',
    params: { page, size },
  });

export const getAuthorInfo = (id) =>
  request({
    url: `/author/${id}`,
    method: 'GET',
    params: id,
    data: {},
  });